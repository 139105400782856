import React, { createContext, useContext, useMemo } from 'react';

import useConfirmationDialog from './useConfirmationDialog';

interface ConfirmationDialogContextValue {
  confirm: ReturnType<typeof useConfirmationDialog>['confirm'];
}

const ConfirmationDialogContext = createContext<ConfirmationDialogContextValue | null>(null);

interface ConfirmationDialogProviderProps {
  children: React.ReactNode;

  /**
   * Optionally override the page's color mode.
   */
  colorMode?: 'dark' | 'light';
}

/**
 * Provides a confirm function bound to a single instance of a ConfirmationDialog component.
 * This is useful for sharing a single confirmation dialog across multiple components.
 */
export const ConfirmationDialogProvider = ({ children, colorMode }: ConfirmationDialogProviderProps) => {
  const { confirm, ConfirmationDialog } = useConfirmationDialog();

  return (
    <ConfirmationDialogContext.Provider
      value={useMemo(
        () => ({
          confirm,
        }),
        [confirm],
      )}
    >
      {children}

      {colorMode ? (
        <div data-color-mode={colorMode}>
          <ConfirmationDialog />
        </div>
      ) : (
        <ConfirmationDialog />
      )}
    </ConfirmationDialogContext.Provider>
  );
};

/**
 * Hook to access a confirm function bound to a single instance of the ConfirmationDialog component
 * rendered by the ConfirmationDialogProvider.
 */
export function useConfirmationDialogContext() {
  const value = useContext(ConfirmationDialogContext);
  if (!value) throw new Error('ConfirmationDialogContext.Provider is missing');
  return value;
}
